import { IArrayItem } from './shared-interface';
import {
  AppointmentBookingType,
  AppointmentRecordType,
  AppointmentStatus,
  Gender,
  MaritalStatusEnum,
  PackageStatus,
  PaymentMode,
  PaymentStatus,
  Role,
  TransactionType,
} from './shared.enum';

export const customMobilePatterns = '00000000||000000000||0000000000||00000000000';

export const TitleArr = ['Mr', 'Mrs', 'Ms', 'Miss', 'Master', 'Sir', 'Dr', 'Professor', 'Lady'];

export const FieldErrorMsg = 'Please fill all required fields.';

export const GenderArr: IArrayItem[] = [
  {
    title: 'Male',
    value: Gender.Male,
  },
  {
    title: 'Female',
    value: Gender.Female,
  },
];
export const MaritalStatusArr: IArrayItem[] = [
  {
    title: 'Single',
    value: MaritalStatusEnum.Single,
  },
  {
    title: 'Engaged',
    value: MaritalStatusEnum.Engaged,
  },
  {
    title: 'Married',
    value: MaritalStatusEnum.Married,
  },
  {
    title: 'Defacto',
    value: MaritalStatusEnum.Defacto,
  },
  {
    title: 'Divorced',
    value: MaritalStatusEnum.Divorced,
  },
  {
    title: 'Separated',
    value: MaritalStatusEnum.Separated,
  },
  {
    title: 'Widowed',
    value: MaritalStatusEnum.Widowed,
  },
];

export const RoleArr: IArrayItem[] = [
  {
    title: 'Super Admin',
    value: Role.SuperAdmin,
  },
  {
    title: 'Tenant',
    value: Role.Admin,
  },
  {
    title: 'Corporate',
    value: Role.Corporate,
  },
  {
    title: 'Individual',
    value: Role.Individual,
  },
  {
    title: 'Employee',
    value: Role.Employee,
  },
];

export const PackageDurationArr: IArrayItem[] = [
  {
    title: '1 Month',
    value: 1,
  },
  {
    title: '3 Months',
    value: 3,
  },
  {
    title: '6 Months',
    value: 6,
  },
  {
    title: '9 Months',
    value: 9,
  },
  {
    title: '12 Months',
    value: 12,
  },
];

export const PaymentStatusArr: IArrayItem[] = [
  {
    title: 'Initiated',
    value: PaymentStatus.Initiated,
  },
  {
    title: 'Completed',
    value: PaymentStatus.Completed,
  },
  {
    title: 'Cancelled',
    value: PaymentStatus.Cancelled,
  },
  {
    title: 'Failed',
    value: PaymentStatus.Failed,
  },
];

export const PackageStatusArr: IArrayItem[] = [
  {
    title: 'Unpaid',
    value: PackageStatus.Unpaid,
  },
  {
    title: 'Paid',
    value: PackageStatus.Paid,
  },
  {
    title: 'Partially Paid',
    value: PackageStatus.PartiallyPaid,
  },
  {
    title: 'Cancelled',
    value: PackageStatus.Cancelled,
  },
];

export const TransactionTypeArr: IArrayItem[] = [
  {
    title: 'Package',
    value: TransactionType.Package,
  },
  {
    title: 'Session',
    value: TransactionType.Session,
  },
];

export const PaymentModeArr: IArrayItem[] = [
  {
    title: 'Cash',
    value: PaymentMode.Cash,
  },
  {
    title: 'Cheque',
    value: PaymentMode.Cheque,
  },
  {
    title: 'Online',
    value: PaymentMode.Online,
  },
  {
    title: 'Bank Transfer',
    value: PaymentMode.BankTransfer,
  },
];

export const AppointmentStatusArr: IArrayItem[] = [
  {
    title: 'Pending',
    value: AppointmentStatus.Pending,
  },
  {
    title: 'Cancelled',
    value: AppointmentStatus.Cancelled,
  },
  {
    title: 'Completed',
    value: AppointmentStatus.Completed,
  },
];

export const BookingTypeArr: IArrayItem[] = [
  {
    title: 'New',
    value: AppointmentBookingType.New,
  },
  {
    title: 'Repeat',
    value: AppointmentBookingType.Repeat,
  },
];

export const RecordTypeArr: IArrayItem[] = [
  {
    title: 'Specialized Program',
    value: AppointmentRecordType.SpecializedProgram,
  },
  {
    title: 'Program',
    value: AppointmentRecordType.Program,
  },
];
