<form [formGroup]="form" class="mt-2">
  <mat-horizontal-stepper [linear]="false" #hstepper>
    <mat-step *ngFor="let type of assessmentTypesArr; let i = index" [stepControl]="getSubFormGroup(type.formGroupName)">
      <ng-template matStepLabel> {{ type.displayName }}</ng-template>
      <!-- <p class="text-2xl text-left my-5 font-bold italic underline">{{ type.subHeading }}</p> -->

      <div class="mt-5">
        <ng-container *ngIf="filterSections(type) as sections">
          <a (click)="scrollToSection('Section-' + section.id)" *ngFor="let section of filterSections(type); let idx = index">
            <span class="text-lg cursor-pointer text-teal-500">
              {{ section.displayName }}
            </span>
            <span *ngIf="idx != sections?.length - 1"> | </span>
          </a>
        </ng-container>
      </div>

      <div [id]="'Section-' + section.id" *ngFor="let section of filterSections(type)">
        <p class="text-2xl text-left my-5">{{ section.displayName }}</p>

        <form [formGroupName]="type.formGroupName" class="mt-2">
          <div *ngFor="let question of filterQuestions(type, section)">
            <div class="my-7">
              <ng-container
                *ngIf="question.questionType == 'text' || question.questionType == 'email' || question.questionType == 'number'"
              >
                <mat-label class="font-medium">Q{{ question.questionNo }}) {{ question.question }}</mat-label>
                <br />
                <mat-form-field class="w-full sm:w-2/3">
                  <input
                    matInput
                    [type]="question.questionType"
                    [min]="question.min"
                    [max]="question.max"
                    [formControlName]="question.formControlName"
                    required
                  />
                </mat-form-field>
              </ng-container>
              <ng-container *ngIf="question.questionType == 'radio'">
                <mat-label class="font-medium">Q{{ question.questionNo }}) {{ question.question }}</mat-label>
                <br />
                <mat-radio-group color="primary" [formControlName]="question.formControlName">
                  <ul class="flex flex-wrap">
                    <ng-container *ngFor="let option of question.options">
                      <li
                        class="mat-option"
                        [ngClass]="{ selected: form.value[type.formGroupName][question.formControlName] == option.value }"
                      >
                        <mat-radio-button [disableRipple]="true" [value]="option.value">
                          <span class="p-5">{{ option.option == null ? option.value : option.option }}</span>
                        </mat-radio-button>
                      </li>
                    </ng-container>
                  </ul>
                </mat-radio-group>
                <div class="p-2"></div>
              </ng-container>
              <ng-container *ngIf="question.questionType == 'select' || question.questionType == 'multiselect'">
                <mat-label class="font-medium">Q{{ question.questionNo }}) {{ question.question }}</mat-label>
                <br />
                <mat-form-field class="w-full sm:w-2/3">
                  <mat-select
                    color="primary"
                    [formControlName]="question.formControlName"
                    [multiple]="question.questionType == 'multiselect'"
                  >
                    <ng-container *ngFor="let option of question.options">
                      <mat-option [value]="option.value">
                        {{ option.option == null ? option.value : option.option }}
                      </mat-option>
                    </ng-container>
                  </mat-select>
                </mat-form-field>
              </ng-container>
              <ng-container *ngIf="question.questionType == 'label'">
                <mat-label [innerHTML]="question.question"> </mat-label>
              </ng-container>
            </div>
          </div>
        </form>
      </div>
      <div class="mt-5">
        <button mat-raised-button color="primary" matStepperPrevious *ngIf="i != 0" class="mr-2 mt-4">Previous</button>
        <ng-container *ngIf="i + 1 != assessmentTypesArr.length">
          <button
            mat-raised-button
            color="primary"
            matStepperNext
            *ngIf="!isReadOnly"
            (click)="partialSave(type.formGroupName)"
            class="mt-4"
          >
            Save & Next
          </button>
          <button mat-raised-button color="primary" matStepperNext *ngIf="isReadOnly" class="mt-4">Next</button>
        </ng-container>
      </div>

      <div class="mt-2 text-danger" *ngIf="!isReadOnly && getSubFormGroup(type.formGroupName, form).invalid">
        You have missed
        <span class="h4">
          {{ ('0' + controlsRecursive(getSubFormGroup(type.formGroupName, form))?.invalidControls?.length).slice(-2) }}
        </span>
        questions. <br />
        Please answer all the mentioned questions to move to next section
      </div>
    </mat-step>
  </mat-horizontal-stepper>

  <div class="ml-4 mt-5" *ngIf="!isReadOnly && assessmentTypesArr.length">
    <button mat-raised-button color="primary" (click)="form.valid ? submit() : ''" class="mt-4" [disabled]="!form.valid">
      Submit Assessment
    </button>
    <br />

    <small *ngIf="!form.valid"> Please answer all questions to submit </small>
  </div>
</form>
