import { Route } from '@angular/router';
import { LayoutComponent } from 'app/layout/layout.component';
import { initialDataResolver } from './app.resolvers';
import { AppOpenOrganizationalAssessmentComponent } from './modules/open/organizational-assessment/assessment.component';
import { AuthGuard } from './shared/guard/auth.guard';
import { NoAuthGuard } from './shared/guard/noAuth.guard';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [
  { path: '', pathMatch: 'full', redirectTo: 'sign-in' },
  { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'dashboard' },

  // Auth routes for guests
  {
    path: '',
    canActivate: [NoAuthGuard],
    canActivateChild: [NoAuthGuard],
    component: LayoutComponent,
    data: {
      layout: 'empty',
    },
    children: [
      {
        path: '',
        loadChildren: () => import('app/modules/auth/auth.module').then((m) => m.AuthModule),
      },
      {
        path: 'open',
        children: [
          {
            path: 'organizational-assessment/:corporateUniqueCode',
            component: AppOpenOrganizationalAssessmentComponent,
          },
        ],
      },
    ],
  },

  // Admin routes
  {
    path: '',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    component: LayoutComponent,
    data: {
      layout: 'empty',
    },
    children: [
      {
        path: 'accounts',
        loadChildren: () => import('app/modules/common/choose-account/choose-account.module').then((m) => m.ChooseAccountModule),
      },
    ],
  },

  {
    path: '',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    component: LayoutComponent,
    resolve: {
      initialData: initialDataResolver,
    },
    children: [
      // {
      //   path: '',
      //   redirectTo: 'dashboard',
      //   pathMatch: 'full',
      // },
      {
        path: 'dashboard',
        loadChildren: () => import('app/modules/common/dashboard/dashboard.module').then((m) => m.DashboardModule),
      },
      {
        path: 'profile',
        loadChildren: () => import('app/modules/common/profile/profile.module').then((m) => m.ProfileModule),
      },
      {
        path: 'user-management',
        loadChildren: () => import('app/modules/user-management/user-management.module').then((m) => m.UserManagementModule),
      },
      {
        path: 'tenant',
        loadChildren: () => import('app/modules/tenant/tenant.module').then((m) => m.TenantModule),
      },
      {
        path: 'corporate',
        loadChildren: () => import('app/modules/corporate/corporate.module').then((m) => m.CorporateModule),
      },
      {
        path: 'organizational-assessment',
        loadChildren: () =>
          import('app/modules/organizational-assessment/organizational-assessment.module').then((m) => m.OrganizationalAssessmentModule),
      },
      {
        path: 'webapp',
        loadChildren: () => import('app/modules/webapp/webapp.module').then((m) => m.WebAppModule),
      },
    ],
  },

  { path: '**', pathMatch: 'full', redirectTo: 'sign-in' },
];
