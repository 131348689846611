<div
  class="absolute inset-0 flex flex-col sm:flex-row flex-auto min-w-0 overflow-hidden items-center md:items-start sm:justify-center md:justify-start"
>
  <div class="relative hidden md:flex flex-col items-center justify-center w-1/2 h-full lg:px-28 overflow-hidden bg-white">
    <div class="self-start mb-8 w-40">
      <img src="assets/images/logo/logo.png" alt="" />
    </div>
    <img src="assets/images/pages/auth/login-image.png" alt="" />
  </div>
  <div
    class="flex overflow-y-auto items-center justify-center w-full sm:w-auto h-full md:w-1/2 py-8 px-4 sm:p-12 md:p-16 sm:rounded-2xl md:rounded-none sm:shadow md:shadow-none sm:bg-card bg-theme"
  >
    <div class="w-full max-w-100 sm:w-100 mx-auto sm:mx-0">
      <div class="text-center text-white">
        <!-- Logo -->
        <div class="mb-10 w-40 m-auto md:hidden">
          <img src="assets/images/logo/logo-white.png" alt="" />
        </div>

        <!-- Title -->
        <div class="text-3xl font-extrabold tracking-tight leading-tight uppercase mt-8">
          Wellbe
          <mat-icon [svgIcon]="'heroicons_solid:lock-closed'" class="text-white"></mat-icon>
        </div>
        <div class="mt-0.5 text-2xl">
          <div>Sign in to start your session</div>
        </div>
      </div>

      <!-- Login Form -->
      <form class="mt-8" [formGroup]="loginForm" *ngIf="stepId === 1">
        <mat-form-field floatLabel="always" class="w-full">
          <mat-label class="font-bold text-white">Email </mat-label> <br />
          <input matInput placeholder="user@example.com" formControlName="username" />
          <mat-error class="text-white">
            {{ getErrorMessage('username') }}
          </mat-error>
        </mat-form-field>

        <!-- Password field -->
        <mat-form-field floatLabel="always" class="w-full">
          <mat-label class="font-bold text-white">Password </mat-label> <br />
          <input matInput type="password" placeholder="******" formControlName="password" />
          <mat-error class="text-white">
            {{ getErrorMessage('password') }}
          </mat-error>
        </mat-form-field>

        <div class="text-white font-bold">
          <mat-checkbox class="label-only" [formControl]="loginWithOtp">
            {{ loginWithOtp.value ? 'Login with password' : 'Login with otp' }}
          </mat-checkbox>
        </div>

        <div class="text-center">
          <re-captcha #signInCaptchaRef="reCaptcha" (resolved)="signInCaptchaResolved($event)" size="invisible" [hidden]="isMobileBuild">
          </re-captcha>

          <button
            class="fuse-mat-button-large mt-10 bg-white text-theme font-bold px-15 text-xl"
            mat-flat-button
            [color]="'primary'"
            [disabled]="loginForm.disabled"
            (click)="isMobileBuild ? signIn() : verifySignInCaptcha(signInCaptchaRef)"
          >
            <span *ngIf="!loginForm.disabled"> Sign In </span>
            <mat-progress-spinner *ngIf="loginForm.disabled" [diameter]="24" [mode]="'indeterminate'"> </mat-progress-spinner>
          </button>
        </div>

        <!-- <div class="text-white text-center mt-3 font-medium">
          New User?
          <a class="font-extrabold" routerLink="/sign-up">Sign up now!</a>
        </div> -->
      </form>

      <!-- Otp Form -->
      <form class="mt-8" [formGroup]="otpForm" *ngIf="stepId === 2">
        <!-- Otp field -->
        <div class="w-full text-center">
          <mat-label class="font-bold text-white"> Authentication Code </mat-label> <br />
          <div class="mt-2">
            <ng-otp-input
              [config]="{ length: 6, allowNumbersOnly: true }"
              (onInputChange)="otpForm?.get('otp')?.setValue($event)"
            ></ng-otp-input>
            <mat-error class="text-white text-sm mt-0.5">
              {{ getErrorMessage('otp', otpForm) }}
            </mat-error>
          </div>
        </div>

        <div class="text-center">
          <re-captcha #signInCaptchaRef="reCaptcha" (resolved)="signInCaptchaResolved($event)" size="invisible" [hidden]="isMobileBuild">
          </re-captcha>

          <button
            class="fuse-mat-button-large mt-10 bg-white text-theme font-bold px-15 text-xl"
            mat-flat-button
            [color]="'primary'"
            [disabled]="otpForm.disabled"
            (click)="isMobileBuild ? signIn() : verifySignInCaptcha(signInCaptchaRef)"
          >
            <span *ngIf="!otpForm.disabled"> Validate OTP </span>
            <mat-progress-spinner *ngIf="otpForm.disabled" [diameter]="24" [mode]="'indeterminate'"> </mat-progress-spinner>
          </button>

          <div class="mt-6 font-medium">
            <div class="text-white">Didn't receive authentication code?</div>
            <ng-container class="mt-3">
              <button type="button" class="text-white font-bold" (click)="getOtp()" [disabled]="!canSendOtp" *ngIf="canSendOtp">
                Resend
              </button>
              <span class="text-white" *ngIf="!canSendOtp">
                {{ 'Can resend code after ' + sendOtpTimer + ' seconds' }}
              </span>
            </ng-container>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
