import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CorporateResModel } from 'app/dtos/corporate/corporate.model';
import { IApiListResponse, IApiResponse } from 'app/shared/shared-interface';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class CorporateService {
  constructor(private _httpClient: HttpClient) {}

  Create(data: any): Observable<IApiResponse<CorporateResModel>> {
    return this._httpClient.post<IApiResponse<null>>(`${environment.apiUrl}/v1.0/corporate`, data);
  }

  GetAll(param: HttpParams): Observable<IApiListResponse<CorporateResModel>> {
    return this._httpClient.get<IApiResponse<null>>(`${environment.apiUrl}/v1.0/corporate`, { params: param });
  }

  Get(id: string): Observable<IApiResponse<CorporateResModel>> {
    return this._httpClient.get<IApiResponse<null>>(`${environment.apiUrl}/v1.0/corporate/${id}`);
  }

  Update(id: string, data: any): Observable<IApiResponse<CorporateResModel>> {
    return this._httpClient.put<IApiResponse<null>>(`${environment.apiUrl}/v1.0/corporate/${id}`, data);
  }

  Delete(id: string): Observable<IApiResponse<CorporateResModel>> {
    return this._httpClient.delete<IApiResponse<null>>(`${environment.apiUrl}/v1.0/corporate/${id}`);
  }
}
