import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CountryResModel } from 'app/dtos/country/country.model';
import { IApiListResponse, IApiResponse } from 'app/shared/shared-interface';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class CountryService {
  constructor(private _httpClient: HttpClient) {}

  Create(data: any): Observable<IApiResponse<CountryResModel>> {
    return this._httpClient.post<IApiResponse<null>>(`${environment.apiUrl}/v1.0/country`, data);
  }

  GetAll(param: HttpParams): Observable<IApiListResponse<CountryResModel>> {
    return this._httpClient.get<IApiResponse<null>>(`${environment.apiUrl}/v1.0/country`, { params: param });
  }

  Get(id: number): Observable<IApiResponse<CountryResModel>> {
    return this._httpClient.get<IApiResponse<null>>(`${environment.apiUrl}/v1.0/country/${id}`);
  }

  Update(id: number, data: any): Observable<IApiResponse<CountryResModel>> {
    return this._httpClient.put<IApiResponse<null>>(`${environment.apiUrl}/v1.0/country/${id}`, data);
  }

  Delete(id: number): Observable<IApiResponse<CountryResModel>> {
    return this._httpClient.delete<IApiResponse<null>>(`${environment.apiUrl}/v1.0/country/${id}`);
  }
}
