import { CommonModule } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormArray, FormControl, FormGroup, FormsModule, ReactiveFormsModule, ValidatorFn, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AssessmentQuestionOptionResModel } from 'app/dtos/assessment-question-option/assessment-question-option.model';
import { AssessmentQuestionResModel } from 'app/dtos/assessment-question/assessment-question.model';
import { AssessmentSectionResModel } from 'app/dtos/assessment-section/assessment-section.model';
import { AssessmentTypeResModel } from 'app/dtos/assessment-type/assessment-type.model';
import { CorporateResModel } from 'app/dtos/corporate/corporate.model';
import { CountryResModel } from 'app/dtos/country/country.model';
import { OrganizationalAssessmentResModel } from 'app/dtos/organizational-assessment/organizational-assessment.model';
import { AssessmentTypeService } from 'app/services/assessment-type.service';
import { CorporateService } from 'app/services/corporate.service';
import { CountryService } from 'app/services/country.service';
import { OrganizationalAssessmentService } from 'app/services/organizational-assessment.service';
import { focusInvalidInput } from 'app/shared/custom.validators';
import { NotificationService } from 'app/shared/services/notification.service';
import _ from 'lodash';
import { finalize } from 'rxjs';
import { AuthService } from '../../../services/auth.service';

interface IAssessmentQuestionResModelExt extends AssessmentQuestionResModel {
  typeId: number;
  formGroupName: string;
}
interface IAssessmentQuestionOptionResModelExt extends AssessmentQuestionOptionResModel {
  sectionId: number;
  typeId: number;
}

@Component({
  selector: 'organizational-assessment',
  templateUrl: './assessment.component.html',
  styleUrls: ['./assessment.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatTooltipModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatSelectModule,
    MatIconModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatStepperModule,
    MatRadioModule,
  ],
  providers: [CountryService, AuthService, CorporateService, OrganizationalAssessmentService, AssessmentTypeService],
})
export class OrganizationalAssessmentCommonComponent implements OnInit, OnDestroy, OnChanges {
  @Input() isReadOnly: boolean = false;
  @Input() corporateId: string;
  @Input() email: string;
  @Output() isFormSubmitted = new EventEmitter<boolean>();
  @Output() totalQuestions = new EventEmitter<number>();
  @Output() completionPercentage = new EventEmitter<number>();
  @Output() assessmentTypes = new EventEmitter<AssessmentTypeResModel[]>();

  form: FormGroup;
  isSubmitted: boolean;
  countries: CountryResModel[] = [];
  assessment: OrganizationalAssessmentResModel = new OrganizationalAssessmentResModel();
  totalQuestionsCount = 0;
  token: string;
  assessmentTypesArr: AssessmentTypeResModel[] = [];
  assessmentQuestions: IAssessmentQuestionResModelExt[] = [];
  assessmentQuestionsOptions: IAssessmentQuestionOptionResModelExt[] = [];
  corporate: CorporateResModel = new CorporateResModel();

  constructor(
    private _corporateService: CorporateService,
    private _assessmentService: OrganizationalAssessmentService,
    private _countryService: CountryService,
    private _assessmentTypeService: AssessmentTypeService,
    private _notificationService: NotificationService,
    private _el: ElementRef,
  ) {
    this.form = new FormGroup({});
  }

  ngOnInit() {
    // document.getElementsByTagName('body')[0].style.minWidth = '1200px';
    this.fetchCorporate(this.corporateId);
    this.prepareDropDown();

    this.form.valueChanges.subscribe(() => {
      this.calculateCompletionPercentage();
    });
  }

  ngOnDestroy(): void {
    // document.getElementsByTagName('body')[0].style.removeProperty('min-width');
  }

  ngOnChanges(): void {
    if (this.corporateId && this.email) this.getAssessmentByCorporateEmail();

    if (this.isReadOnly) this.form.disable();
  }

  validateForm(form: FormGroup): boolean {
    form.markAllAsTouched();
    this.isSubmitted = true;
    if (form.invalid) {
      focusInvalidInput(this._el);
    }
    return form.valid;
  }

  getSubFormGroup(formGroupName: string, form: FormGroup = this.form): FormGroup {
    return form.get(formGroupName) as FormGroup;
  }

  prepareDropDown(): void {
    this.fetchQuestions();
    this.fetchCountries();
  }

  fetchCorporate(corporateId: string): void {
    this._corporateService.Get(corporateId).subscribe((apiRes) => {
      this.corporate = apiRes.data;

      // Corporate Name
      this.getSubFormGroup('AssessmentType1')?.get('Q1').setValue(this.corporate.name);
      this.getSubFormGroup('AssessmentType1')?.get('Q1').disable();

      // Corporate Address
      this.getSubFormGroup('AssessmentType1')?.get('Q2').setValue(this.corporate.address);
      this.getSubFormGroup('AssessmentType1')?.get('Q2').disable();
    });
  }

  fetchCountries(): void {
    let param = new HttpParams()
      .append('PageOffset', 0)
      .append('PageSize', 10000)
      .append('OrderBy', 'name')
      .append('OrderDirection', 'ASC');
    this._countryService.GetAll(param).subscribe((apiRes) => {
      this.countries = apiRes.data.list;

      // var section = _.find(this.questionsArr, { formGroupName: 'organizationDetails' });
      // var elem = _.find(section.questions, { formControlName: 'yourCountry' });
      // if (elem) {
      //   elem.options = _.map(this.countries, (country) => {
      //     return { label: country.name, value: country.shortName };
      //   });
      // }
    });
  }

  fetchQuestions(): void {
    this.assessmentQuestions = [];
    this.assessmentQuestionsOptions = [];
    this.totalQuestionsCount = 0;

    let param = new HttpParams()
      .append('PageOffset', 0)
      .append('PageSize', 10000)
      .append('OrderBy', 'displayOrder')
      .append('OrderDirection', 'ASC');
    this._assessmentTypeService.GetAll(param).subscribe((apiRes) => {
      this.assessmentTypesArr = apiRes.data.list as AssessmentTypeResModel[];

      this.assessmentTypesArr.forEach((type) => {
        type.formGroupName = `AssessmentType${type.id}`;
        let questionsGroup = new FormGroup({});

        type.sections.forEach((section) => {
          section.questions.forEach((question) => {
            let quesExt = question as IAssessmentQuestionResModelExt;
            quesExt.formGroupName = type.formGroupName;
            quesExt.typeId = type.id;
            this.assessmentQuestions.push(quesExt);

            question.formControlName = `Q${question.id}`;
            if (question.questionType != 'label') {
              questionsGroup.addControl(question.formControlName, new FormControl(null, this.computeValidators(question)));
            }

            question.options.forEach((optn) => {
              let optnExt = optn as IAssessmentQuestionOptionResModelExt;
              optnExt.sectionId = section.id;
              optnExt.typeId = type.id;
              this.assessmentQuestionsOptions.push(optnExt);
            });
          });
          this.form.addControl(type.formGroupName, questionsGroup);
          this.totalQuestionsCount += section.questions.length;
        });
      });

      this.form.patchValue(this.assessment.assessment);

      // Corporate Name
      this.getSubFormGroup('AssessmentType1')?.get('Q1').setValue(this.corporate.name);
      this.getSubFormGroup('AssessmentType1')?.get('Q1').disable();

      // Corporate Address
      this.getSubFormGroup('AssessmentType1')?.get('Q2').setValue(this.corporate.address);
      this.getSubFormGroup('AssessmentType1')?.get('Q2').disable();

      // User Email
      this.getSubFormGroup('AssessmentType1')?.get('Q4').setValue(this.email);
      this.getSubFormGroup('AssessmentType1')?.get('Q4').disable();

      this.assessmentTypes.emit(this.assessmentTypesArr);
      this.totalQuestions.emit(this.totalQuestionsCount);
    });
  }

  getAssessment(id: string): void {
    this._assessmentService.Get(id, this.token).subscribe((apiRes) => {
      this.assessment = apiRes.data;
      this.form.patchValue(apiRes.data.assessment);
    });
  }

  getAssessmentByCorporateEmail(): void {
    this._assessmentService.GetByCorporateEmail(this.corporateId, this.email, this.token).subscribe((apiRes) => {
      this.assessment = apiRes.data;
      // if (this.assessment.isSubmitted) this.isFormSubmitted.emit(true);
      this.form.patchValue(apiRes.data.assessment);
    });
  }

  submit(): void {
    if (!this.validateForm(this.form)) return;

    if (this.assessment?.id) {
      this.update(true);
    } else {
      this.create(true);
    }
  }

  create(isSubmitted: boolean): void {
    this.form.disable();

    let reqData = {
      corporateId: this.corporateId,
      email: this.email,
      assessment: this.form.getRawValue(),
      isSubmitted: isSubmitted,
    };

    this._assessmentService
      .Create(reqData, this.token)
      .pipe(
        finalize(() => {
          this.form.enable();
          this.getSubFormGroup('AssessmentType1')?.get('Q1').disable();
          this.getSubFormGroup('AssessmentType1')?.get('Q2').disable();
          this.getSubFormGroup('AssessmentType1')?.get('Q4').disable();
        }),
      )
      .subscribe((apiRes) => {
        this._notificationService.notify(apiRes.message);
        this.getAssessment(apiRes.data);
      });
  }

  update(isSubmitted: boolean): void {
    this.form.disable();

    let reqData = {
      corporateId: this.corporateId,
      email: this.email,
      assessment: this.form.getRawValue(),
      isSubmitted: isSubmitted,
    };

    this._assessmentService
      .Update(this.assessment.id, reqData, this.token)
      .pipe(
        finalize(() => {
          this.form.enable();
          this.getSubFormGroup('AssessmentType1')?.get('Q1').disable();
          this.getSubFormGroup('AssessmentType1')?.get('Q2').disable();
          this.getSubFormGroup('AssessmentType1')?.get('Q4').disable();
        }),
      )
      .subscribe((apiRes) => {
        this._notificationService.notify(apiRes.message);
        if (isSubmitted) this.isFormSubmitted.emit(true);
      });
  }

  partialSave(form: string) {
    if (this.getSubFormGroup(form, this.form).valid) {
      if (this.assessment.id) {
        this.update(false);
      } else {
        this.create(false);
      }
    }
  }

  calculateCompletionPercentage(): void {
    let controls = this.controlsRecursive(this.form);
    let validControlsCount = controls?.validControls?.length ?? 0;
    let invalidControlsCount = controls?.invalidControls?.length ?? 0;
    let requiredControlsCount = controls?.requiredControls?.length ?? 0;

    this.totalQuestionsCount = requiredControlsCount;
    let completionPercentage = ((this.totalQuestionsCount - invalidControlsCount) * 100) / this.totalQuestionsCount;

    this.totalQuestions.emit(this.totalQuestionsCount);
    this.completionPercentage.emit(completionPercentage);
  }

  controlsRecursive(formToInvestigate: FormGroup | FormArray): {
    validControls: string[];
    invalidControls: string[];
    requiredControls: string[];
  } {
    var validControls: string[] = [];
    var invalidControls: string[] = [];
    var requiredControls: string[] = [];
    let recursiveFunc = (form: FormGroup | FormArray) => {
      Object.keys(form.controls).forEach((field) => {
        const control = form.get(field);
        if (control instanceof FormGroup) {
          recursiveFunc(control);
        } else if (control instanceof FormArray) {
          recursiveFunc(control);
        } else {
          if (control.invalid) invalidControls.push(field);
          else validControls.push(field);

          if (control.hasValidator(Validators.required)) {
            requiredControls.push(field);
          }
        }
      });
    };
    recursiveFunc(formToInvestigate);
    return { validControls: validControls, invalidControls: invalidControls, requiredControls: requiredControls };
  }

  canShowQuestion(ques: AssessmentQuestionResModel): boolean {
    try {
      if (ques.dependencies.length) {
        var dependency = ques.dependencies[0];

        let compiledCondition = '';
        var conditionElements = dependency.condition.split(' ');
        conditionElements?.forEach((elem) => {
          if (elem.startsWith('Q')) {
            let id = elem.replaceAll('Q', '');
            if (ques) {
              let ques = _.find(this.assessmentQuestions, { id: parseInt(id) }) as IAssessmentQuestionResModelExt;
              let dependentForm = this.getSubFormGroup(ques.formGroupName, this.form);
              let dependentPropertyValue = dependentForm.value[ques.formControlName];
              compiledCondition += ` '${dependentPropertyValue}' `;
            }
          } else if (elem.startsWith('O')) {
            let id = elem.replaceAll('O', '');
            let option = _.find(this.assessmentQuestionsOptions, { id: parseInt(id) }) as IAssessmentQuestionOptionResModelExt;
            if (option) {
              compiledCondition += ` '${option.value}' `;
            }
          } else {
            compiledCondition += ` ${elem} `;
          }
        });

        const evaluatedResult = eval(compiledCondition);

        return evaluatedResult;
      }
      return true;
    } catch (ex) {
      return false;
    }
  }

  filterQuestions(type: AssessmentTypeResModel, section: AssessmentSectionResModel): AssessmentQuestionResModel[] {
    let filteredQuestions: AssessmentQuestionResModel[] = [];

    let questionNo = 1;
    section.questions?.forEach((ques) => {
      let canShowQuestion = this.canShowQuestion(ques);
      if (canShowQuestion) filteredQuestions.push(ques);

      if (ques.questionType != 'label') {
        if (canShowQuestion) {
          ques.questionNo = questionNo++;
          this.getSubFormGroup(type.formGroupName, this.form).get(ques.formControlName).setValidators(this.computeValidators(ques));
        } else {
          this.getSubFormGroup(type.formGroupName, this.form).get(ques.formControlName).clearValidators();
          this.getSubFormGroup(type.formGroupName, this.form).get(ques.formControlName).updateValueAndValidity();
        }
      }
    });

    return filteredQuestions;
  }

  canShowSection(section: AssessmentSectionResModel): boolean {
    try {
      if (section.dependencies.length) {
        var dependency = section.dependencies[0];

        let compiledCondition = '';
        var conditionElements = dependency.condition.split(' ');
        conditionElements?.forEach((elem) => {
          if (elem.startsWith('Q')) {
            let id = elem.replaceAll('Q', '');
            let ques = _.find(this.assessmentQuestions, { id: parseInt(id) }) as IAssessmentQuestionResModelExt;
            if (ques) {
              let dependentForm = this.getSubFormGroup(ques.formGroupName, this.form);
              let dependentPropertyValue = dependentForm.value[ques.formControlName];
              compiledCondition += ` '${dependentPropertyValue}' `;
            }
          } else if (elem.startsWith('O')) {
            let id = elem.replaceAll('O', '');
            let option = _.find(this.assessmentQuestionsOptions, { id: parseInt(id) }) as IAssessmentQuestionOptionResModelExt;
            if (option) {
              compiledCondition += ` '${option.value}' `;
            }
          } else {
            compiledCondition += ` ${elem} `;
          }
        });

        const evaluatedResult = eval(compiledCondition);

        return evaluatedResult;
      }

      return true;
    } catch (ex) {
      return false;
    }
  }

  filterSections(type: AssessmentTypeResModel): AssessmentSectionResModel[] {
    let filteredSections: AssessmentSectionResModel[] = [];

    type.sections?.forEach((sec) => {
      let canShowSection = this.canShowSection(sec);
      if (canShowSection) filteredSections.push(sec);
      sec.questions.forEach((ques) => {
        if (ques.questionType != 'label') {
          if (canShowSection) {
            this.getSubFormGroup(type.formGroupName, this.form).get(ques.formControlName).setValidators(this.computeValidators(ques));
          } else {
            this.getSubFormGroup(type.formGroupName, this.form).get(ques.formControlName).clearValidators();
            this.getSubFormGroup(type.formGroupName, this.form).get(ques.formControlName).updateValueAndValidity();
          }
        }
      });
    });

    return filteredSections;
  }

  computeValidators(ques: AssessmentQuestionResModel): ValidatorFn[] {
    return [
      Validators.required,
      ques.questionType == 'email' ? Validators.email : Validators.nullValidator,
      ques.min != null ? Validators.min(ques.min) : Validators.nullValidator,
      ques.max != null ? Validators.max(ques.max) : Validators.nullValidator,
      ques.maxLength != null ? Validators.maxLength(ques.maxLength) : Validators.nullValidator,
    ];
  }

  scrollToSection(sectionId: string) {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }
}
