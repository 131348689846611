export class OrganizationalAssessmentResModel {
  id: string;
  email: string;
  assessment: any;
  isSubmitted: boolean;
  summaries: OrganizationalAssessmentSummaryResModel[];
}

export class OrganizationalAssessmentSearchReqModel {
  constructor() {
    this.searchColumns = [];
    this.isSubmittedFilter = null;
  }
  searchColumns: string[];
  isSubmittedFilter: boolean;
}

export class OrganizationalAssessmentSummaryResModel {
  id: number;
  assessmentId: string;
  scoreObtained: number;
  summaryType: number;
  totalScore: number;
  risk?: number; //internal
}
