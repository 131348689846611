import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ICorporate, User } from 'app/modules/admin/user/user.model';
import { IUserLoginData } from 'app/modules/auth/auth.interface';
import { EncryptionService } from 'app/shared/services/encryption.service';
import { StorageService } from 'app/shared/services/storage.service';
import { IApiResponse } from 'app/shared/shared-interface';
import { environment } from 'environments/environment';
import { Observable, ReplaySubject, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

export interface IUserId {
  userId: string;
}

export interface IJwtTokenData {
  iss: string; // Issuer
  aud: string; // Audience
  exp: number; // Expiry
  nameid: string; // UserId
  sid: string; // SessionId
  tid: string; // TenantId
  primarygroupsid: string; // CorporateId
  groupsid: string; // UserGroupId
  role: string; //Role
  email: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private _tokenData = new ReplaySubject<IJwtTokenData>(1);
  private _user = new ReplaySubject<User>(1);
  private _loginData = new ReplaySubject<IUserLoginData>(1);

  constructor(
    private _httpClient: HttpClient,
    private _router: Router,
    private _storageService: StorageService,
    private _encryptionService: EncryptionService,
  ) {
    const loginDataString = _storageService.getValueFromLocalStorage(environment.storage.loginDataKey);
    if (loginDataString) {
      const loginData = JSON.parse(loginDataString) as IUserLoginData;
      this.loginData = loginData;
      this.accessToken = loginData.accessToken;
      this.tokenData = this._encryptionService.decodeJwtToken(loginData.accessToken);
      this.user = loginData.user;
    }
  }

  set accessToken(token: string) {
    this._storageService.setValueToLocalStorage(environment.storage.loginTokenKey, token);
  }

  get accessToken$(): string {
    return this._storageService.getValueFromLocalStorage(environment.storage.loginTokenKey) ?? '';
  }

  deleteAccessToken$(): void {
    this._storageService.removeLocalStorageKey(environment.storage.loginTokenKey);
  }

  set user(value: User) {
    this._user.next(value);
  }

  get user$(): Observable<User> {
    return this._user.asObservable();
  }

  set loginData(value: IUserLoginData) {
    this._loginData.next(value);
  }

  get loginData$(): Observable<IUserLoginData> {
    return this._loginData.asObservable();
  }

  set tokenData(tokenData: IJwtTokenData) {
    this._tokenData.next(tokenData);
  }

  get tokenData$(): Observable<IJwtTokenData> {
    return this._tokenData.asObservable();
  }

  signUp(data: any): Observable<IApiResponse<IUserId>> {
    return this._httpClient.post<IApiResponse<IUserId>>(`${environment.apiUrl}/v1.0/auth/register`, data);
  }

  signIn(credentials: any): Observable<IApiResponse<IUserLoginData>> {
    return this._httpClient.post<IApiResponse<IUserLoginData>>(`${environment.apiUrl}/v1.0/auth`, credentials).pipe(
      switchMap((apiRes) => {
        if (apiRes.status) {
          const data = apiRes.data;
          this.clearAllStorage();
          this._storageService.setValueToLocalStorage(environment.storage.loginDataKey, JSON.stringify(data));
          this.accessToken = data.accessToken;
          this.tokenData = this._encryptionService.decodeJwtToken(data.accessToken);
          this.user = data.user;
        }
        return of(apiRes);
      }),
    );
  }

  signInUsingToken(): Observable<boolean> {
    return this._httpClient.get<IApiResponse<IUserLoginData>>(`${environment.apiUrl}/v1.0/auth/generate-refresh-token`).pipe(
      switchMap((apiRes) => {
        if (apiRes.status) {
          const data = apiRes.data;
          this.clearAllStorage();
          this._storageService.setValueToLocalStorage(environment.storage.loginDataKey, JSON.stringify(data));
          this.accessToken = data.accessToken;
          this.tokenData = this._encryptionService.decodeJwtToken(data.accessToken);
          this.user = data.user;

          // Return true
          return of(true);
        }

        // Return false
        return of(false);
      }),
    );
  }

  signOut(): void {
    this.clearAllStorage();
    this._router.navigateByUrl('/sign-in');
  }

  clearAllStorage(): void {
    this._storageService.clearLocalStorage();
    this._storageService.clearSessionStorage();
    this._storageService.clearCookies();
  }

  generateUserGroupToken(id: string): Observable<boolean> {
    return this._httpClient.get<IApiResponse<IUserLoginData>>(`${environment.apiUrl}/v1.0/auth/generate-user-group-token/${id}`).pipe(
      switchMap((apiRes) => {
        if (apiRes.status) {
          const data = apiRes.data;
          this.clearAllStorage();
          this._storageService.setValueToLocalStorage(environment.storage.loginDataKey, JSON.stringify(data));
          this.accessToken = data.accessToken;
          this.tokenData = this._encryptionService.decodeJwtToken(data.accessToken);
          this.user = data.user;

          // Return true
          return of(true);
        }

        // Return false
        return of(false);
      }),
    );
  }

  sendLoginOtp(data: any): Observable<IApiResponse<null>> {
    return this._httpClient.post<IApiResponse<null>>(`${environment.apiUrl}/v1.0/auth/send-login-otp`, data);
  }

  sendOrganizationalAssessmentOtp(data: any): Observable<IApiResponse<null>> {
    return this._httpClient.post<IApiResponse<null>>(`${environment.apiUrl}/v1.0/auth/send-organizational-assessment-otp`, data);
  }

  sendVerificationOtp(data: any): Observable<IApiResponse<IUserId>> {
    return this._httpClient.post<IApiResponse<IUserId>>(`${environment.apiUrl}/v1.0/auth/send-verification-otp`, data);
  }

  verifyAccount(data: any): Observable<IApiResponse<null>> {
    return this._httpClient.post<IApiResponse<null>>(`${environment.apiUrl}/v1.0/auth/verify-verification-otp`, data);
  }

  verifyResetPasswordOtp(data: any): Observable<IApiResponse<null>> {
    return this._httpClient.post<IApiResponse<null>>(`${environment.apiUrl}/v1.0/auth/verify-reset-password-otp`, data);
  }

  sendResetPasswordOtp(data: any): Observable<IApiResponse<IUserId>> {
    return this._httpClient.post<IApiResponse<IUserId>>(`${environment.apiUrl}/v1.0/auth/send-reset-password-otp`, data);
  }

  getProfile(): Observable<IApiResponse<User>> {
    return this._httpClient.get<IApiResponse<User>>(`${environment.apiUrl}/v1.0/auth`).pipe(
      switchMap((response) => {
        this.user = response.data;
        return of(response);
      }),
    );
  }

  getUserAccounts(): Observable<IApiResponse<ICorporate[]>> {
    return this._httpClient.get<IApiResponse<ICorporate[]>>(`${environment.apiUrl}/v1.0/auth/accounts`);
  }

  generateOrganizationalAssessmentToken(data: any): Observable<string> {
    return this._httpClient
      .post<IApiResponse<{ loginResponse: IUserLoginData; corporateId: string }>>(
        `${environment.apiUrl}/v1.0/auth/generate-organizational-assessment-token`,
        data,
      )
      .pipe(
        switchMap((apiRes) => {
          if (apiRes.status) {
            const loginResponse = apiRes.data.loginResponse;
            this.clearAllStorage();
            this._storageService.setValueToLocalStorage(environment.storage.loginDataKey, JSON.stringify(loginResponse));
            this.accessToken = loginResponse.accessToken;
            this.tokenData = this._encryptionService.decodeJwtToken(loginResponse.accessToken);
            this.user = loginResponse.user;

            // Return corporate id
            return of(apiRes.data.corporateId);
          }

          // Return null
          return of(null);
        }),
      );
  }

  updateProfile(data: any): Observable<IApiResponse<User>> {
    return this._httpClient.put<IApiResponse<User>>(`${environment.apiUrl}/v1.0/auth`, data);
  }
}
