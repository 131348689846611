export const environment = {
  production: false,
  isDevelopment: false,
  isMobileBuild: false,
  storage: {
    loginDataKey: 'WellbeUSRLOGDAT',
    loginTokenKey: 'WellbeUSRLOGTOKENDAT',
    registerationDetailsKey: 'WellbeUSRREGDAT',
    resetPasswordKey: 'WellbeUSRRPDAT',
    organizationalAssessmentTokenKey: 'WellbeOATOKEN',
  },
  apiUrl: 'https://stg-score-api.wellbe.fit',
  recaptchaKey: '6LcEPFgoAAAAACkBPunOxOY6F9of2wH1T7yV544E',
};
