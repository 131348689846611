import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AssessmentTypeResModel } from 'app/dtos/assessment-type/assessment-type.model';
import { IApiListResponse, IApiResponse } from 'app/shared/shared-interface';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class AssessmentTypeService {
  constructor(private _httpClient: HttpClient) {}

  Create(data: any): Observable<IApiResponse<string>> {
    let header = new HttpHeaders();
    return this._httpClient.post<IApiResponse<null>>(`${environment.apiUrl}/v1.0/assessment-type`, data, {
      headers: header,
    });
  }

  GetAll(param: HttpParams): Observable<IApiListResponse<AssessmentTypeResModel>> {
    return this._httpClient.get<IApiResponse<null>>(`${environment.apiUrl}/v1.0/assessment-type`, { params: param });
  }

  Get(id: string): Observable<IApiResponse<AssessmentTypeResModel>> {
    let header = new HttpHeaders();
    return this._httpClient.get<IApiResponse<null>>(`${environment.apiUrl}/v1.0/assessment-type/${id}`, {
      headers: header,
    });
  }

  Update(id: string, data: any): Observable<IApiResponse<AssessmentTypeResModel>> {
    let header = new HttpHeaders();
    return this._httpClient.put<IApiResponse<null>>(`${environment.apiUrl}/v1.0/assessment-type/${id}`, data, {
      headers: header,
    });
  }

  Delete(id: string): Observable<IApiResponse<AssessmentTypeResModel>> {
    return this._httpClient.delete<IApiResponse<null>>(`${environment.apiUrl}/v1.0/assessment-type/${id}`);
  }
}
