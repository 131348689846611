import { Gender, Role } from 'app/shared/shared.enum';
import { CorporateResModel } from '../corporate/corporate.model';

export class UserManagementSearchReqModel {
  constructor() {
    this.searchColumns = [];
    this.isActiveFilter = null;
  }
  searchColumns: string[];
  isActiveFilter: string;
  isProfileUpdatedFilter: string;
  roleFilter: string;
}

export class UserManagementResModel {
  id: string;
  firstName: string;
  middleName: string;
  lastName: string;
  mobile: string;
  email: string;
  isActive: boolean;
  corporates: UserCorporateMappingModel[];
  createdBy: string;
  createdOn: string;
  modifiedOn: string;
  modifiedBy: string;
}

export class UserCorporateMappingModel {
  id?: string;
  corporateId?: string;
  corporate?: CorporateResModel;
  userId?: string;
  basicProfile?: UserBasicProfileResModel;
  isActive?: boolean;
  role?: Role;
}

export class UserBasicProfileResModel {
  id: string;
  userGroupId: string;
  dob: string;
  gender: Gender;
  address: string;
  countryId: number;
  stateId: number;
  cityId: number;
  pincode: string;
  preferredLanguage: string;
  isActive: boolean;
  createdBy: string;
  createdOn: string;
  modifiedOn: string;
  modifiedBy: string;
}
