export enum ActionEnum {
  Add = 1,
  View,
  Edit,
  Delete,
}

export enum PermissionEnum {
  Create = 1,
  CreateSelf,
  Read,
  ReadSelf,
  Update,
  UpdateSelf,
  Delete,
  DeleteSelf,
}

export enum Role {
  SuperAdmin = 1,
  Admin,
  Corporate,
  Employee,
  Individual,
}

export enum Gender {
  Male = 1,
  Female,
  Transgender,
}

export enum LoginType {
  TwoFactorAuthentication = 1,
  Password,
  Otp,
}

export enum DeviceType {
  Web = 1,
  Android,
  iOS,
}

export enum AuthType {
  Email = 1,
  Mobile,
}

export enum MaritalStatusEnum {
  Single = 1,
  Engaged,
  Married,
  Defacto,
  Divorced,
  Separated,
  Widowed,
}

export enum ModuleEnum {
  // Auth
  Tenant = 1,
  Corporate,
  User = 100,
  Auth,
  // Master
  Country = 200,
  State,
  City,
  Goal,
  ServiceCategory,
  SpecializedCategory,
  ProgramCategory,
  Program,
  HealthIssueCategory,
  HealthIssue,
  Package,
  CustomizedPackage,
  // Core
  Appointment = 300,
  Transaction,
}

export enum DayOfWeekEnum {
  Sunday = 0,
  Monday,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
  Saturday,
}

export enum PackageType {
  Package = 'PACKAGE',
  Session = 'SESSION',
}

export enum PackageOwnerType {
  Individual = 'INDIVIDUAL',
  Corporate = 'CORPORATE',
}

export enum PackageStatus {
  Unpaid = 'UNPAID',
  Paid = 'PAID',
  PartiallyPaid = 'PARTIALLY-PAID',
  Cancelled = 'CANCELLED',
}

export enum PaymentStatus {
  Initiated = 'INITIATED',
  Completed = 'COMPLETED',
  Cancelled = 'CANCELLED',
  Failed = 'FAILED',
}

export enum TransactionType {
  Package = 'PACKAGE',
  Session = 'SESSION',
}

export enum PaymentMode {
  Cash = 'CASH',
  Online = 'ONLINE',
  Cheque = 'CHEQUE',
  BankTransfer = 'BANK_TRANSFER',
}

export enum AppointmentStatus {
  Pending = 'PENDING',
  Completed = 'COMPLETED',
  Cancelled = 'CANCELLED',
}

export enum AppointmentRecordType {
  SpecializedProgram = 'SPECIALIZED-PROGRAM',
  Program = 'PROGRAM',
}

export enum AppointmentBookingType {
  New = 'NEW',
  Repeat = 'REPEAT',
}

export enum SubscriptionStatus {
  Active = 'ACTIVE',
  Expired = 'EXPIRED',
}

export enum SummaryTypeEnum {
  ProfileData = 1,
  OrganizationEmployeeAssistanceProgram = 2,
  OrganizationHealthInsurance = 3,
  OrganizationHealthWellnessPromotion = 4,
  OrganizationPhysicalHealthFacility = 5,
  OrganizationMentalHealthFacility = 6,
  PhysicalHealthDiabetes = 7,
  PhysicalHealthHyperTension = 8,
  PhysicalHealthDyslipidemia = 9,
  PhysicalHealthCardiovascularDiseases = 10,
  PhysicalHealthChronicObstructivePulmonaryDisease = 11,
  PhysicalHealthOralCancer = 12,
  PhysicalHealthBreastCancer = 13,
  PhysicalHealthCervicalCancer = 14,
  PhysicalHealthMedicalHistory = 15,
  MentalHealthStress = 16,
  MentalHealthDepression = 17,
  MentalHealthAnxietyTest = 18,
  MentalHealthPtsdTest = 19,
  MentalHealthMedicalHistory = 20,
  MentalHealthCurrentMentalProblems = 21,
  GeneralWellbeingWfh = 22,
  GeneralWellbeingWorkLifeBalance = 23,
  GeneralWellbeingWorkPlaceCulture = 24,
  GeneralWellbeingGeneralHealth = 25,
  GeneralWellbeingPatience = 26,
  GeneralWellbeingEi = 27,
  GeneralWellbeingAttitude = 28,
  GeneralWellbeingPersonality = 29,
  GeneralWellbeingCalmness = 30,
  GeneralWellbeingFocus = 31,
  GeneralWellbeingAdaptability = 32,
  HealthRiskPhysicalInactivity = 33,
  HealthRiskExcessiveAlcoholConsumption = 34,
  HealthRiskTobaccoUse = 35,
  HealthRiskPoorDiet = 36,
  HealthRiskMentalDisorder = 37,
  HealthRiskPoorSleepHabits = 38,
  HealthRiskEnvironmentalExposures = 39,
  HealthRiskFamilyHistory = 40,

  // Additional
  Other = 10000,
  Obesity,
  Organization,
  PhysicalHealth,
  MentalHealth,
  GeneralWellbeing,
  HealthRisk,
}

export enum SummaryTypeEnumLabel {
  ProfileData = 'Profile Data',
  OrganizationEmployeeAssistanceProgram = 'Employee Assistance Program',
  OrganizationHealthInsurance = 'Health Insurance',
  OrganizationHealthWellnessPromotion = 'Health & Wellness Promotion',
  OrganizationPhysicalHealthFacility = 'Physical Health Facility',
  OrganizationMentalHealthFacility = 'Mental Health Facility',
  PhysicalHealthDiabetes = 'Diabetes',
  PhysicalHealthHyperTension = 'HyperTension / High Blood Pressure',
  PhysicalHealthDyslipidemia = 'Dyslipidemia (Imbalance of Cholesterol)',
  PhysicalHealthCardiovascularDiseases = 'Cardiovascular Diseases (CVD)',
  PhysicalHealthChronicObstructivePulmonaryDisease = 'Chronic Obstructive Pulmonary Disease (COPD)',
  PhysicalHealthOralCancer = 'Oral Cancer',
  PhysicalHealthBreastCancer = 'Breast Cancer',
  PhysicalHealthCervicalCancer = 'Cervical Cancer',
  PhysicalHealthMedicalHistory = 'Medical History',
  MentalHealthStress = 'Stress',
  MentalHealthDepression = 'Depression',
  MentalHealthAnxietyTest = 'Anxiety Test',
  MentalHealthPtsdTest = 'PTSD Test',
  MentalHealthMedicalHistory = 'Medical History',
  MentalHealthCurrentMentalProblems = 'Current Mental Problems',
  GeneralWellbeingWfh = 'WFH',
  GeneralWellbeingWorkLifeBalance = 'Work Life Balance',
  GeneralWellbeingWorkPlaceCulture = 'Work Place Culture',
  GeneralWellbeingGeneralHealth = 'General Health',
  GeneralWellbeingPatience = 'Patience',
  GeneralWellbeingEi = 'EI',
  GeneralWellbeingAttitude = 'Attitude',
  GeneralWellbeingPersonality = 'Personality',
  GeneralWellbeingCalmness = 'Calmness',
  GeneralWellbeingFocus = 'Focus',
  GeneralWellbeingAdaptability = 'Adaptability',
  HealthRiskPhysicalInactivity = 'Physical Inactivity',
  HealthRiskExcessiveAlcoholConsumption = 'Excessive Alcohol Consumption',
  HealthRiskTobaccoUse = 'Tobacco Use',
  HealthRiskPoorDiet = 'Poor Diet',
  HealthRiskMentalDisorder = 'Mental Disorder',
  HealthRiskPoorSleepHabits = 'Poor Sleep Habits',
  HealthRiskEnvironmentalExposures = 'Environmental Exposures',
  HealthRiskFamilyHistory = 'Genetic Factors / Family History',

  // Additional
  Other = 10000,
  Obesity = 'Obesity',
  Organization = 'Organization',
  PhysicalHealth = 'PhysicalHealth',
  MentalHealth = 'MentalHealth',
  GeneralWellbeing = 'GeneralWellbeing',
  HealthRisk = 'HealthRisk',
}
