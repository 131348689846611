import { TenantResModel } from '../tenant/tenant.model';

export class CorporateSearchReqModel {
  constructor() {
    this.searchColumns = [];
    this.isActiveFilter = null;
    this.countryFilter = null;
    this.stateFilter = null;
    this.cityFilter = null;
  }
  searchColumns: string[];
  isActiveFilter: string;
  countryFilter: string;
  stateFilter: string;
  cityFilter: string;
}

export class CorporateResModel {
  id?: string;
  tenantId?: string;
  tenant?: TenantResModel;
  name?: string;
  address?: string;
  countryId?: number;
  stateId?: number;
  cityId?: number;
  uniqueCode?: string;
  isActive?: boolean;
  isAggregator?: boolean;
  createdBy?: string;
  createdOn?: string;
  modifiedOn?: string;
  modifiedBy?: string;
}
