import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FuseNavigationItem } from '@fuse/components/navigation';
import { Navigation } from 'app/core/navigation/navigation.types';
import { AuthService, IJwtTokenData } from 'app/services/auth.service';
import { Role } from 'app/shared/shared.enum';
import _ from 'lodash';
import { Observable, ReplaySubject, Subject, of, takeUntil } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class NavigationService {
  private _navigation: ReplaySubject<Navigation> = new ReplaySubject<Navigation>(1);
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  accessRole: Role;

  /**
   * Constructor
   */
  constructor(private _httpClient: HttpClient, private _authService: AuthService) {
    this.update();
  }

  filterNavigations(navigationItems: FuseNavigationItem[], role: Role): FuseNavigationItem[] {
    navigationItems = _.filter(navigationItems, (item: FuseNavigationItem) => {
      return item.roles.includes(role);
    });
    navigationItems.forEach((navigationItem) => {
      navigationItem.children = this.filterNavigations(navigationItem.children, role);
    });

    return navigationItems;
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Getter for navigation
   */
  get navigation$(): Observable<Navigation> {
    return this._navigation.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Get all navigation data
   */
  get(): Observable<Navigation> {
    // return this._httpClient.get<Navigation>('api/common/navigation').pipe(
    //     tap((navigation) =>
    //     {
    //         this._navigation.next(navigation);
    //     }),
    // );
    const navigations: Navigation = {
      compact: [],
      default: [],
      futuristic: [],
      horizontal: [],
    };
    return of(navigations);
  }

  update(): void {
    this._authService.tokenData$.pipe(takeUntil(this._unsubscribeAll)).subscribe((tokenData: IJwtTokenData) => {
      this.accessRole = Role[tokenData.role];
      const filteredNavigationItems = this.filterNavigations(this.navigationItems, this.accessRole);
      const filteredNavigations: Navigation = {
        compact: filteredNavigationItems,
        default: filteredNavigationItems,
        futuristic: filteredNavigationItems,
        horizontal: filteredNavigationItems,
      };

      this._navigation.next(filteredNavigations);
    });
  }

  navigationItems: FuseNavigationItem[] = [
    {
      id: 'tenant',
      title: 'Aggregators',
      type: 'basic',
      icon: 'heroicons_outline:cube',
      link: '/tenant',
      roles: [Role.SuperAdmin],
    },
    {
      id: 'corporates',
      title: 'Corporates',
      type: 'basic',
      icon: 'heroicons_outline:cube',
      link: '/corporate',
      roles: [Role.Admin],
    },
    {
      id: 'user-management',
      title: 'User Management',
      type: 'collapsable',
      icon: 'heroicons_outline:table-cells',
      roles: [Role.SuperAdmin, Role.Admin, Role.Corporate],
      children: [
        {
          id: 'super-admin',
          title: 'Super Admin',
          type: 'basic',
          icon: 'heroicons_outline:cube',
          link: '/user-management/super-admin',
          roles: [Role.SuperAdmin],
        },
        {
          id: 'employees',
          title: 'Employees',
          type: 'basic',
          icon: 'heroicons_outline:cube',
          link: '/user-management/employee',
          roles: [Role.Corporate, Role.Admin],
        },
        // {
        //   id: 'individual',
        //   title: 'Individual',
        //   type: 'basic',
        //   icon: 'heroicons_outline:cube',
        //   link: '/user-management/individual',
        //   roles: [Role.Admin],
        // },
      ],
    },
    {
      id: 'organizational-assessment',
      title: 'Organizational Assessment',
      type: 'basic',
      icon: 'heroicons_outline:cube',
      link: '/organizational-assessment',
      roles: [Role.Admin],
    },
    // WebApp

    {
      id: 'webapp-home',
      title: 'Home',
      type: 'basic',
      icon: 'heroicons_outline:home',
      link: '/webapp',
      roles: [Role.Individual, Role.Employee],
    },
    {
      id: 'webapp-assessment-report',
      title: 'Assessment Report',
      type: 'basic',
      icon: 'heroicons_outline:document-chart-bar',
      link: '/webapp/assessment-report',
      roles: [Role.Individual, Role.Employee],
    },
  ];
}
